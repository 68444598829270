<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
    :items="menuItems"
  >
      <template v-slot:activator="{ on, attrs }">
        <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
          <IconDotsH width="18"/>
        </UiBtn>
      </template>
    </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'FolderMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    UiMenu,
    UiBtn
  },
  props: {
    folder: {
      type: Object,
      default: null,
    },
    loadProjectsAndFolders: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'getSpaces',
      'getActiveSpace'
    ]),
    folderSpace() {
      return this.getSpaces.find(el => el?.folders.find(f => f.id === this.folder.folder_id))
    },
    menuItems() {
      const {spaceAdmin} = this.$config.space.userRole;
      return [
        {
          title: 'Rename Folder',
          icon: 'IconPen',
          action: () => {
            this.$store.dispatch('openModal', {
              modalName: 'createEditFolderNameModal',
              data: {
                type: 'edit',
                folderData: {
                  title: this.folder.title,
                  id: this.folder.folder_id,
                  space: this.folderSpace,
                },
              },
              handlers: {
                onUpdate: async () => {
                  const [spaces] = await Promise.all([this.$api.spaces.list(), this.loadProjectsAndFolders()])
                  await this.$store.dispatch('setSpaces', spaces.data);
                },
                onClose: () => {},
                onCreate: async () => {
                  const spaces = await this.$api.spaces.list()
                  await this.$store.dispatch('setSpaces', spaces.data);
                }
              },
            });
          },
          attrs: {
            to: '',
            disabled: this.getActiveSpace?.currentSpaceUser?.user_role.name !== spaceAdmin
          }
        },
        {
          title: 'Delete Folder',
          icon: 'IconDelete',
          action: () => {
            this.$store.dispatch("openModal", {
              modalName: "confirmModal",
              data: {
                title: "Delete Folder",
                description: "Are you sure want to delete this folder? Its content will be archived. This action is irreversible.",
                confirmBtnText: "Delete",
                confirmBtnIcon: '',
                cancelBtnColor: 'gray-60',
                showIconCross: true,
                actionBtnsPosition: 'right',
                modalMaxWidth: '416',
                descriptionAlign: 'left',
                descriptionColor: 'gray-100--text',
              },
              handlers: {
                onCancel: () => {
                  this.$store.dispatch("closeModal", "confirmModal");
                },
                onConfirm: async () => {
                  try {
                    this.$store.dispatch('updateModalData', {
                      modalName: 'confirmModal',
                      data: {
                        loading: true,
                      }
                    })

                    const folderTitle = this.folder.title
                    await this.$api.folder.delete([this.folder.folder_id])

                    await this.$router.$updateQueryParams({page: 1});
                    const [spaces] = await Promise.all([this.$api.spaces.list(), this.loadProjectsAndFolders()])

                    await this.$store.dispatch('setSpaces', spaces.data);

                    this.$store.dispatch('updateModalData', {
                      modalName: 'confirmModal',
                      data: {
                        loading: false,
                      }
                    })
                    this.$toast.open({
                      message: `“${folderTitle}” folder has been deleted`,
                      type: 'success',
                      position: 'top-right'
                    });
                    this.$store.dispatch("closeModal", "confirmModal");
                  } catch(error) {
                    console.error(error);
                    this.$toast.open({
                      message: 'Oops! Something went wrong!',
                      type: 'error',
                      position: 'top-right'
                    });
                    this.$store.dispatch('updateModalData', {
                      modalName: 'confirmModal',
                      data: {
                        loading: false,
                      }
                    })
                  }
                },
              },
            });
          },
          attrs: {
            to: '',
            disabled: this.getActiveSpace?.currentSpaceUser?.user_role.name !== spaceAdmin
          }
        }
      ]
    }
  },
}
</script>
